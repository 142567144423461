<template>
  <div class="logo">
    <img class="logo__image" :src="logo" />
  </div>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    logo: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.logo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 1rem 0;

  &__image {
    max-width: rem(250px);
  }
}

@include breakpoint(small) {
  .logo {
    &__image {
      width: 70%;
    }

    &__rounded {
      border-radius: 50%;
    }
  }
}
</style>
