<template>
  <div>
    <logo class="hidden-lg-and-up" :logo="eventLogo" />
    <v-sheet :dark="theme === 'dark'" class="py-4 py-lg-16">
      <v-container fluid class="px-0 py-0">
        <v-row no-gutters>
          <v-col cols="0" lg="2">
            <logo class="hidden-md-and-down" :logo="eventLogo" type="round" />
            <logo class="hidden-md-and-down" :logo="eventLogo" />
          </v-col>

          <v-col cols="12" lg="6">
            <h2 class="name hidden-lg-and-up px-8">{{ activity.name }}</h2>
            <v-carousel
              :show-arrows="activity.coverImages && activity.coverImages.length > 1"
              :hide-delimiters="activity.coverImages && activity.coverImages.length <= 1"
            >
              <v-carousel-item
                v-for="(item, index) in activity.coverImages"
                :key="index"
                :src="item"
              />
            </v-carousel>
          </v-col>

          <v-col cols="12" lg="3" class="pl-lg-16">
            <h2 class="name hidden-md-and-down">{{ activity.name }}</h2>
            <h2 class="date px-8 px-lg-0">{{ $d(Date.parse(activity.startTime), 'long') }}</h2>

            <p class="description pt-2 px-8 px-lg-0 justify" data-test-id="description">
              <read-more
                v-if="activity.description"
                :less="$t('globals.readLess')"
                :more="$t('globals.readMore')"
                :text="activity.description"
                data-test-id="read-more"
              />
            </p>

            <div class="d-flex flex-column align-center">
              <toggle-agenda
                class="mb-4"
                :is-on-user-agenda="isOnUserAgenda"
                :is-sending="isSending"
                @add-to-agenda="addToAgenda"
                @remove-from-agenda="removeFromAgenda"
                data-test-id="toggle-agenda-btn"
              />

              <v-btn
                v-if="isStarted()"
                class="mb-4"
                color="primary"
                width="275px"
                outlined
                dark
                tile
                x-large
                @click.native="participate"
                data-test-id="participate-btn"
              >
                {{ $t('schedule.actions.join') }}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="0" lg="1" />
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import DateUtil from '@/helpers/date/date.helper';
import ReadMore from '@/components/read-more/ReadMore.vue';
import Logo from '@/components/logo/Logo.vue';
import ToggleAgenda from '../agenda/ToggleAgenda.vue';

export default {
  name: 'ConferenceDetail',
  props: {
    theme: {
      type: String,
      default: 'light',
    },
    activity: {
      type: Object,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    isOnUserAgenda: {
      type: Boolean,
      default: false,
    },
    isSending: {
      type: Boolean,
      default: false,
    },
    now: {
      type: String,
      default: new Date().toISOString(),
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    localIsSending: false,
  }),
  components: {
    Logo,
    ReadMore,
    ToggleAgenda,
  },
  computed: {
    eventLogo() {
      return this.event?.logoWeb;
    },
  },
  methods: {
    addToAgenda() {
      this.$emit('add-to-agenda', this.activity);
    },
    removeFromAgenda() {
      this.$emit('remove-from-agenda', this.activity);
    },
    isStarted() {
      return DateUtil.canAccessConference(this.activity, this.now, this.event) || !!this.isAdmin;
    },
    participate() {
      this.$emit('participate', this.activity);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/core/variables';

.description {
  white-space: pre-line;
}

.justify {
  text-align: justify;
}

@include carousel-fix();
</style>
