<template>
  <div>
    <v-btn
      v-if="!isOnUserAgenda"
      color="primary"
      width="275px"
      outlined
      dark
      tile
      x-large
      :loading="isSending && localIsSending"
      @click.native="addToAgenda"
      >{{ $t('schedule.actions.addToAgenda') }}
    </v-btn>
    <v-btn
      v-else
      color="primary"
      width="275px"
      outlined
      dark
      tile
      x-large
      :loading="isSending && localIsSending"
      @click.native="removeFromAgenda"
      >{{ $t('schedule.actions.removeFromAgenda') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'ToggleAgenda',
  props: {
    isOnUserAgenda: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSending: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    localIsSending: false,
  }),
  methods: {
    addToAgenda() {
      this.localIsSending = true;
      this.$emit('add-to-agenda');
    },
    removeFromAgenda() {
      this.localIsSending = true;
      this.$emit('remove-from-agenda');
    },
  },
  watch: {
    isSending(_, oldVal) {
      if (oldVal) {
        this.localIsSending = false;
      }
    },
  },
};
</script>
